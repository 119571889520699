import React from 'react'
import { Link } from 'react-router-dom'
import { SectionTitle } from '../elements/SectionTitle'

import { CardTalent } from '../cards/CardTalent'

import av1 from '../../assets/talents/Av1.png'
import av2 from '../../assets/talents/Av2.png'
import av3 from '../../assets/talents/Av3.png'
import av4 from '../../assets/talents/Av4.png'
import av5 from '../../assets/talents/Av5.png'
import av6 from '../../assets/talents/Av6.png'

import pic1 from '../../assets/talents/c1.svg'
import pic2 from '../../assets/talents/c2.svg'
import pic3 from '../../assets/talents/c3.svg'
import pic4 from '../../assets/talents/c4.svg'
import pic5 from '../../assets/talents/c5.svg'
import pic6 from '../../assets/talents/c6.svg'

const Talents = () => {
  return (
    <section id='candidats' className='auto'>
      <div className='container text-center'>
        <SectionTitle
          title="Discover Our Talented Performers"
          text="Find the Perfect Match for Your Team"
        />
        <div className="s-40"></div>
        <div className="row">
          <div className="col-md-4">
            <CardTalent
              projects='356'
              experience='25+'
              rating='⭐ ⭐ ⭐ ⭐ ⭐'
              name='Chris'
              avatar={av1}
              url={pic1}
              position='DevOps,DevSecOps'
              desc='Seasoned DevOps engineer specializing in secure and efficient CI/CD pipelines.'
              price='150'
            />
          </div>
          <div className="col-md-4">
            <CardTalent
              projects='255'
              experience='23+'
              rating='⭐ ⭐ ⭐ ⭐ ⭐'
              name='Lisa'
              avatar={av2}
              url={pic2}
              position='Mobile App Development, UX Research'
              desc='Dedicated mobile app developer experienced in conducting user research for seamless user experiences.'
              price='55'
            />
          </div>
          <div className="col-md-4">
            <CardTalent
              projects='172'
              experience='6+'
              rating='⭐ ⭐ ⭐ ⭐'
              name='David'
              avatar={av3}
              url={pic3}
              position='Video Editing, Motion Graphics'
              desc='Talented video editor specializing in dynamic motion graphics.'
              price='45'
            />
          </div>
          <div className="col-md-4">
            <CardTalent
              projects='138'
              experience='12+'
              rating='⭐ ⭐ ⭐ ⭐ ⭐'
              name='Emily'
              avatar={av4}
              url={pic4}
              position='Web Development, UX/UI Design'
              desc='Passionate web developer with expertise in creating user-friendly interfaces.'
              price='40'
            />
          </div>
          <div className="col-md-4">
            <CardTalent
              projects='54'
              experience='5+'
              rating='⭐ ⭐ ⭐ ⭐'
              name='John'
              avatar={av5}
              url={pic5}
              position='Graphic Design, Illustration'
              desc='Creative graphic designer specializing in captivating illustrations.'
              price='35'
            />
          </div>
          <div className="col-md-4">
            <CardTalent
              projects='36'
              experience='10+'
              rating='⭐ ⭐ ⭐ ⭐ ⭐'
              name='Sarah'
              avatar={av6}
              url={pic6}
              position='Content Writing, Copyediting'
              desc='Experienced content writer skilled in crafting engaging and polished copy.'
              price='30'
            />
          </div>
        </div>
        <div className="s-40"></div>
        <p className='fz-16 c-v60 w600 w-70 ma'>Find your perfect match among our extensive pool of talented professionals. With a vast database of skilled individuals, you can quickly assemble a team tailored to meet any of your current project needs.</p>
        <div className="s-40"></div>
        <Link to='/login' className='button mx-2'>Discover a diverse pool of talent now</Link>
      </div>
    </section>
  )
}

export {Talents}
