import React from 'react'
import {Link} from 'react-router-dom'
import {CtaTitle} from '../elements/CtaTitle'

const CtaTwo = () => {
  return (
    <section className='mtx'>
      <div className='container text-center'>
      <div className="s-100"></div>
      <CtaTitle
        title="Hire Directly"
        text="Directly Connect with Top Professionals"
      />
      <div className="s-50"></div>
      <Link to='/login' className='button bg-sub'>Hire an Expert</Link>
      </div>
    </section>
  )
}

export {CtaTwo}
