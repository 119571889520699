import React, {useState,useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom'

const SinglePost = () => {

  const navigate = useNavigate()

  const {id} = useParams()
  const back = () => navigate(-1)


  const [post,setPost] = useState(null)

  useEffect(() => {
    fetch(`https://jsonplaceholder.typicode.com/posts/${id}`)
      .then(res => res.json())
      .then(data => setPost(data))
  },[id])


  return (
    <>
      {
        post && (
          <>
          <h1>{post.title}</h1>
          <p>{post.id} - {post.userId}</p>
          <hr />
          <p>{post.body}</p>
          <button onClick={back}>Back</button>
          <button onClick={() => navigate('/', {state: post.title})}>Home</button>
          </>
        )
      }
    </>
  )
}

export {SinglePost}
