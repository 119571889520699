import React from 'react'

const CardReview = (props) => {
  return (
    <div className="card-rev rad-24 mx-3">
      <div className="flex aic">
        <img src={props.url} alt={props.name} width={64} />
        <div className="w-10"></div>
        <div>
          <h4 className="fz-20 c-p100 w400 text-left">{props.name}</h4>
          <p className="fz-16 c-v60 w500 lh-175 text-left">{props.company}</p>
          <p className="c-white fz-14 w-500 text-left">{props.position}</p>
        </div>
      </div>
      <div className="s-30"></div>
      <p className="fz-14 c-white w-500 text-left h-60">
        {props.text}
      </p>
    </div>
  )
}

export {CardReview}
