import React from 'react'
import {Link} from 'react-router-dom'
import {CtaTitle} from '../elements/CtaTitle'

const CtaOne = () => {
  return (
    <section className='cta'>
      <div className='container text-center'>
      <div className="s-100"></div>
      <CtaTitle
        title="Find Skilled Talent"
        text="Discover the Perfect Team for Your Project"
      />
      <div className="s-50"></div>
      <Link to='candidats' className='button bg-sub'>Find Talent Now</Link>
      </div>
    </section>
  )
}

export {CtaOne}
