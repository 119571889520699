import React from 'react'

const SectionTitle = (props) => {
  return (
    <div className="text-center w-60 ma">
      <h2 className='fz-18 c-p80 upp'>{props.title}</h2>
      <h1 className='sub fz-32 py-2'>{props.text}</h1>
    </div>
  )
}

export {SectionTitle}
