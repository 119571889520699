import { useNavigate } from "react-router-dom"
import "./Registration.scss"
import axios from "axios"
import { useState, useEffect } from "react"

const Registration = () => {
  const navigate = useNavigate()
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [showRedirectMessage, setShowRedirectMessage] = useState(false)

  const [emailInputValue, setEmailInputValue] = useState("")
  const [passwordInputValue, setPasswordInputValue] = useState("")
  const [firstNameInputValue, setFirstNameInputValue] = useState("")
  const [lastNameInputValue, setLastNameInputValue] = useState("")
  const [accountType, setAccountType] = useState(null)

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isPasswordValid, setIsPasswordValid] = useState(true)

  const hasUppercase = /[A-Z]/.test(passwordInputValue)
  const hasNumber = /\d/.test(passwordInputValue)

  useEffect(() => {
    const isInputEmpty =
      !emailInputValue ||
      !passwordInputValue ||
      !firstNameInputValue ||
      !lastNameInputValue ||
      !accountType

    setIsButtonDisabled(
      isInputEmpty ||
        !hasUppercase ||
        !hasNumber ||
        passwordInputValue.length <= 8 ||
        !accountType
    )
  }, [
    emailInputValue,
    passwordInputValue,
    firstNameInputValue,
    lastNameInputValue,
    accountType,
    hasNumber,
    hasUppercase
  ])

  const newUser = {
    email: emailInputValue,
    password: passwordInputValue,
    first_name: firstNameInputValue,
    last_name: lastNameInputValue,
    account_type: accountType ? "performer" : "company",
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    try {
      axios
        .post("https://api.heyboss.online/api/register/", newUser)
        .then(() => {
          setIsSuccess(true)
          setShowRedirectMessage(true)
        })
        .catch((error) => {
          error && setIsError(true)
        })
    } catch (error) {
      console.log(error.response.status)
    }
  }

  useEffect(() => {
    if (showRedirectMessage) {
      const redirectTimer = setTimeout(() => {
        navigate("/login")
      }, 5000)

      return () => {
        clearTimeout(redirectTimer)
      }
    }
  }, [showRedirectMessage, navigate])

  return (
    <section className="bg-dark w-100 h-100 flex aic jcc hero">
      <div className="container text-center">
        <div className="s-100"></div>
        <div className="s-100"></div>
        <h3 className="title-large c-blue">Registration</h3>
        <div className="s-40"></div>
        <form
          onSubmit={handleSubmit}
          className="bg-theme w-40 ma px-3 py-4 rounded"
        >
          {isSuccess ? (
            <div>
              You're successfully registered. Redirecting to login page...
            </div>
          ) : (
            <div className="form-input-container">
              <label className="title-small c-white">
                First Name:
                <input
                  type="text"
                  name="firstname"
                  value={firstNameInputValue}
                  onChange={(e) => setFirstNameInputValue(e.target.value)}
                  className={`form-input bg-light c-dark px-3`}
                />
              </label>
              <label className="title-small c-white">
                Last Name:
                <input
                  type="text"
                  name="lastname"
                  value={lastNameInputValue}
                  onChange={(e) => setLastNameInputValue(e.target.value)}
                  className={`form-input bg-light c-dark px-3`}
                />
              </label>
              <label className="title-small c-white">
                E-Mail:
                <input
                  type="email"
                  name="email"
                  value={emailInputValue}
                  onChange={(e) => setEmailInputValue(e.target.value)}
                  className={`form-input bg-light c-dark px-3`}
                />
              </label>
              <label className="title-small c-white">
                Password:
                <input
                  type="password"
                  name="password"
                  value={passwordInputValue}
                  onChange={(e) => setPasswordInputValue(e.target.value)}
                  onBlur={() => {
                    setIsPasswordValid(
                      hasUppercase &&
                        hasNumber &&
                        passwordInputValue.length >= 8
                    )
                  }}
                  className={`form-input bg-light c-dark px-3`}
                />
              </label>
              {!isPasswordValid && (
                <p className="password-error">
                  Password must contain at least 8 characters, uppercase
                  letters, and numbers.
                </p>
              )}
              <div className="checkbox-container">
                <label className="title-small c-white">
                  <input
                    type="checkbox"
                    name="accounttype"
                    checked={accountType === "performer"}
                    onChange={() => setAccountType("performer")}
                  />
                  Performer
                </label>
                <label className="title-small c-white">
                  <input
                    type="checkbox"
                    name="accounttype"
                    checked={accountType === "company"}
                    onChange={() => setAccountType("company")}
                  />
                  Company
                </label>
              </div>
              {isError && <p>This user is already exist 😲</p>}
              <button
                type="submit"
                className="button"
                disabled={isButtonDisabled}
              >
                Register
              </button>
            </div>
          )}
        </form>
        <div className="s-100"></div>
        <div className="s-100"></div>
      </div>
    </section>
  )
}

export {Registration}
