import React from 'react'
import { useAuth } from '../../hook/useAuth'
// import {useNavigate} from 'react-router-dom'

const ProfilePage = () => {

  const {user} = useAuth()

  // const {signOut} = useAuth()
  // const navigate = useNavigate()

  return (
    // <>
    // <div>ProfilePage</div>
    // <button onClick={() => signOut(() => navigate('/', {replace: true}))}>Logout</button>
    // </>
    <section className="bg-dark w-100 h-100 flex aic jcc hero">
    <div className="container text-center">
      <div className="s-100"></div>
      <div className="s-100"></div>
      <h3 className='title-large c-blue'>Welcome, {user[0]} {user[1]}</h3>
      <div className="s-40"></div>
      <div className="s-100"></div>
      <div className="s-100"></div>
    </div>
    </section>
  )
}

export {ProfilePage}
