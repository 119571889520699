import React from 'react'
import { CardStep } from '../cards/CardStep'
import { SectionTitle } from '../elements/SectionTitle'

import step1 from '../../assets/back/step1.svg'
import step2 from '../../assets/back/step2.svg'
import step3 from '../../assets/back/step3.svg'

const Steps = () => {
  return (
    <section id='how' className='auto bg-dark'>
      <div className='container'>
        <SectionTitle
          title="Seamless Workflow, Perfect Collaboration"
          text="Effortlessly Connect, Build, and Discuss with Your Dream Team"
        />
        <div className="s-40"></div>
        <div className="row cards-step">
          <div className="col-md-6 flex aic jcc">
            <img src={step1} className='w-100 obfc' alt={'Find and select the desired professional'} />
          </div>
          <div className="col-md-6 flex aic jcc">
            <CardStep
              step='Step 1'
              title='Find and select the desired professional'
              text='The user finds a professional who meets their requirements.'
            />
          </div>
        </div>
        <div className="row cards-step">
          <div className="col-md-6 flex aic jcc c-1">
            <CardStep
              step='Step 2'
              title='Add the selected professional to the team using the builder.'
              text='The user forms a team, including the desired specialist.'
            />
          </div>
          <div className="col-md-6 flex aic jcc c-2">
            <img src={step2} className='w-100 obfc' alt={'Get the cost estimate and access the chat for project discussions'} />
          </div>
        </div>
        <div className="row cards-step">
          <div className="col-md-6 flex aic jcc">
            <img src={step3} className='w-100 obfc' alt={'Add the selected professional to the team using the builder.'} />
          </div>
          <div className="col-md-6 flex aic jcc">
            <CardStep
              step='Step 3'
              title='Get the cost estimate and access the chat for project discussions'
              text='The user receives a preliminary cost estimate and access to the chat to discuss the project with the selected professional.'
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export {Steps}
