import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'

import { useAuth } from '../../hook/useAuth'
import Logo from '../../logo.svg'

function Nav() {

  const navigate = useNavigate()
  const {user, signOut} = useAuth()

  return (
    <Navbar className='nav-top py-3'>
      <Container>
        <Navbar.Brand href="/">
          <img
            src={Logo}
            width="64"
            height="64"
            alt="HeyBoss"
          />
          <span className="px-1"></span>
          <Navbar.Text className='pl-3 sub fz12 w600 title-logo'>HeyBoss</Navbar.Text>
        </Navbar.Brand>

        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {
            user ?
              <>
                <Navbar.Text className='title-small c-white'>
                  Welcome, <Link to='/profile'>{user[0]} {user[1]}</Link>
                </Navbar.Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button className='button mini' onClick={() => signOut(() => navigate('/', {replace: true}))}>Logout</button>
              </>
            : <Link to='/profile' className='button mini bg-sub'>Login</Link>
          }
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export {Nav}
