import React from 'react'
import { Link } from 'react-router-dom'
import { SectionTitle } from '../elements/SectionTitle'
import {CardAbout} from '../cards/CardAbout'

import pic1 from '../../assets/about/a1.svg'
import pic2 from '../../assets/about/a2.svg'
import pic3 from '../../assets/about/a3.svg'
import pic4 from '../../assets/about/a4.svg'

const About = () => {
  return (
    <section id='about' className='auto'>
      <div className='container text-center'>
        <SectionTitle
          title="About HeyBoss"
          text="Connecting Companies with Top Talent"
        />
        <div className="s-40"></div>
        <div className="row">
          <div className="col-md-6">
            <CardAbout
              title='Chat to  Discuss a Project'
              url={pic1}
              text="Communication is key to successful collaborations. Our integrated chat feature enables seamless communication between you and the talents you're interested in. Easily discuss project details, requirements, and timelines, ensuring everyone is on the same page and working towards a shared goal."
            />
          </div>
          <div className="col-md-6">
            <CardAbout
              title='Fixed Rate Per Hour'
              url={pic2}
              text="Transparency and fairness are at the core of our pricing model. We offer a fixed hourly rate, providing clarity and predictability for budgeting and project planning. With HeyBoss, you can rest assured knowing that you're getting top-quality talent at a competitive and consistent rate."
            />
          </div>
          <div className="col-md-6">
            <CardAbout
              title='Discover Skilled Talents'
              url={pic3}
              text='At HeyBoss, we bring together a diverse pool of highly skilled talents to meet your project needs. Our platform is designed to connect you with top professionals in various industries, ensuring that you have access to the best talent available.'
            />
          </div>
          <div className="col-md-6">
            <CardAbout
              title='Reveal Select Contacts'
              url={pic4}
              text="Our curated cards provide you with a snapshot of each talent's expertise, experience, and portfolio. We handpick the most qualified individuals, allowing you to easily identify and connect with the right contacts for your project. With just a glance, you can find the perfect match for your team."
            />
          </div>
        </div>
        <div className="s-40"></div>
        <Link to='#about' className='button blue mx-2'>subscribe</Link>
        <Link to='/login' className='button mx-2'>Join now</Link>
        <div className="s-40"></div>
        <p className='fz-16 c-v60 w600 w-70 ma'>Join HeyBoss today and discover a world of talented professionals ready to bring your projects to life. Experience the ease of connecting, communicating, and collaborating with skilled experts who are passionate about delivering outstanding results. Together, let's unlock your team's full potential.</p>
      </div>
    </section>
  )
}

export {About}
