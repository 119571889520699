import React from 'react'

const BigTitle = (props) => {
  return (
    <div className="text-center w-60 ma">
      <h2 className='title-big c-p100'>{props.title}</h2>
      <div className="s-10"></div>
      <h1 className='sub big'>{props.text}</h1>
    </div>
  )
}

export {BigTitle}
