import React from 'react'
import Slider from 'react-slick'

import { CardReview } from '../cards/CardReview'

import pic1 from '../../assets/reviews/r01.png'
import pic2 from '../../assets/reviews/r02.png'
import pic3 from '../../assets/reviews/r03.png'

function RevSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };
  return (
    <Slider {...settings}>
      <CardReview
        url={pic1}
        name='Sarah D.'
        company='HQ Solutions'
        position='Project Manager'
        text='"HeyBoss not only provided us with exceptional talent but also made the contract process effortless. We were able to find a highly skilled professional who perfectly fit our project requirements. The platform allowed us to directly connect with the specialist and discuss the project details. The experience was fantastic, and we were extremely satisfied with the outcome. Thank you, HeyBoss, for delivering top-notch professionals and streamlining the contract process!"'
      />
      <CardReview
        url={pic2}
        name='David T.'
        company='M&D Agency'
        position='Marketing Director'
        text='"Being able to directly hire a specialist through HeyBoss was a game-changer for us. We needed an expert in digital marketing, and HeyBoss connected us with the right person. The process was quick, and we were able to negotiate the terms directly. Working with the professional was a breeze, and the results exceeded our expectations. Thank you, HeyBoss, for simplifying the hiring process!"'
      />
      <CardReview
        url={pic3}
        name='Emily S.'
        company='Tech & So'
        position='HR Manager'
        text='"HeyBoss helped us find the perfect team for our project. Their platform made it easy to browse through talented professionals and select the ones that matched our requirements. The communication and collaboration process was seamless, and we were able to successfully complete our project on time. Highly recommend HeyBoss for anyone looking to build a skilled team!"'
      />
    </Slider>
  )
}

export {RevSlider}
