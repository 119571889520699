import React from 'react'
import { useParams } from 'react-router-dom'

const SingleTalent = () => {
  const {id, price} = useParams()
  return (
    <>
    <h1 style={{color: 'black'}}>{id} - {price}</h1>
    <div>SingleTalent</div>
    </>
  )
}

export {SingleTalent}
