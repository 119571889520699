import React from 'react'

const CardTalent = (props) => {
  return (
    <div className="card-talent rad-24 card my-3 mx-1">
      <div className="flex aife jcsb card-t-top">
        <div className="bg-p100 c-white t-left text-center">
          <span className="fz-20 w600 lh-1">{props.projects}</span><br className='lh-1' />
          <span className="fz-12 lh-1 w500">project</span>
        </div>
        <img src={props.avatar} alt={props.name} width={64} />
        <div className="bg-v80 c-white t-right text-center">
          <span className="fz-20 w600 lh-1">{props.experience}</span><br className='lh-1'/>
          <span className="fz-12 lh-1 w500">y/exp.</span>
        </div>
      </div>
      <div className="w-100 py-2 text-center my-4">
        <h3 className="fz-20 c-v60 py-2">{props.name}</h3>
        <p className="fz-16">{props.rating}</p>
      </div>
      <div className="w-100 h-10 flex jcc aic card-t-skills">
        <img src={props.url} alt="skills" />
      </div>
      <div className="w-100 flex jcc aic h-40">
        <div className="content mx-5">
          <h4 className="fz-18 w-700 c-v60">{props.position}</h4>
          <p className="c-white fz-14 w400">{props.desc}</p>
        </div>
      </div>
      <div className="w-100 card-t-footer flex jcsb aic py-4 rad">
        <div className="flex aic">
          <span className="fz-32 c-p100 w600">${props.price}</span>
          <span className="fz-16 c-v60 p-1 mt-2">/hour</span>
        </div>
        <a href="/profile" className="button mini bg-pink">show</a>
      </div>
    </div>
  )
}

export {CardTalent}
