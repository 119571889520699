import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

const BlogPage = () => {

  const [posts,setPosts] = useState([])

  useEffect(() => {
    fetch('https://jsonplaceholder.typicode.com/posts')
      .then(res => res.json())
      .then(data => setPosts(data))
  },[])

  return (
    <>
      {
        posts.map(post => (
          <Link key={post.id} to={`/posts/${post.id}`}>
            <p>{post.title}</p>
          </Link>
        ))
      }
    </>
  )
}

export {BlogPage}
