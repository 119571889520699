import React from 'react'
import { Link } from 'react-router-dom'
import { SectionTitle } from '../elements/SectionTitle'
import {RevSlider} from './RevSlider'

const Reviews = () => {
  return (
    <section id='reviews' className='auto'>
      <div className='container text-center'>
        <SectionTitle
          title="Hear from Our Users"
          text="Real Stories, Real Results"
        />
        <div className="s-60"></div>
        <RevSlider />
        <div className="s-60"></div>
        <Link to='/reviews' className='button'>view all</Link>
      </div>
    </section>
  )
}

export {Reviews}
