import React from 'react'

const CardAbout = (props) => {
  return (
    <div className="card-rev rad-24 m-3">
      <div className="flex aic">
        <img src={props.url} alt={props.title} width={48} />
        <div className="mx-2"></div>
        <h3 className="fz-18 c-v60 w600 text-left">{props.title}</h3>
      </div>
      <div className="s-30"></div>
      <p className="fz-16 c-white w-400 text-left h-30">
        {props.text}
      </p>
    </div>
  )
}

export {CardAbout}
