import React from 'react'
import { About } from '../home/About'
import {Header} from '../home/Header'
import { Talents } from '../home/Talents'
import { CtaOne } from '../home/CtaOne'
import { Steps } from '../home/Steps'
import { Calculator } from '../home/Calculator'
import { CtaTwo } from '../home/CtaTwo'
import { Reviews } from '../home/Reviews'
import {Footer} from '../home/Footer'

const HomePage = () => {
  return (
    <>
      <Header />
      <About />
      <Talents />
      <Calculator />
      <CtaOne />
      <Steps />
      <CtaTwo />
      <Reviews />
      <Footer />
    </>
  );
};

export { HomePage };
