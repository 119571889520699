import React from 'react'

const CtaTitle = (props) => {
  return (
    <div className="text-center w-60 ma">
      <h2 className='title-big'>{props.title}</h2>
      <div className="s-10"></div>
      <h1 className='sub w800 fz-40'>{props.text}</h1>
    </div>
  )
}

export {CtaTitle}
