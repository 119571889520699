import React from 'react'

const FormTitle = (props) => {
  return (
    <div className="text-center w-50 ma">
      <h2 className='fz-18 c-p80 upp'>{props.title}</h2>
      <h1 className='sub fz-20 py-2'>{props.text}</h1>
    </div>
  )
}

export {FormTitle}
