import React from 'react'
import {BigTitle} from '../elements/BigTitle'

const Header = () => {
  return (
    <section id='home' className='hd'>
      <div className='container text-center'>
      <div className="s-100"></div>
      <BigTitle
        title="Welcome to HeyBoss!"
        text="Find the Best Talent for Your Team"
      />
      <div className="s-30"></div>
      <p className='fz-18 c-v60 w500 w-60 ma'>Join HeyBoss today and unlock new opportunities for your team.<br />Our platform is ready to help you achieve success and bring your most ambitious projects to life.</p>
      <div className="s-40"></div>
      <a href='#about' className='button'>Start Now</a>
      </div>
    </section>
  )
}

export {Header}
