import React from 'react'

const CardStep = (props) => {
  return (
    <div className="card-step rad-24">
      <h4 className="fz-32 c-p100 w400">{props.step}</h4>
      <p className="fz-20 c-v60 w500 mt-3 mb-4 lh-175">{props.title}</p>
      <p className="c-white fz-18 w-500">{props.text}</p>
    </div>
  )
}

export {CardStep}
