import React from 'react'

import gIcon from '../../assets/social/github.svg'
import lIcon from '../../assets/social/linkedin.svg'
import fIcon from '../../assets/social/facebook.svg'

import logo from '../../assets/logo-mini.svg'

function Footer() {

  const gUrl = 'https://github.com'
  const lUrl = 'https://linkedin.com'
  const fUrl = 'https://facebook.com'

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-3 text-left pt-2">
            <a href="/"><img src={logo} alt="HeyBoss" /></a>
            <p className="fz-18 w700 my-3 c-v60 lh-125">Find the Best Talent for Your Team</p>
            <p className="fz-14 c-white w-500">Join HeyBoss today and unlock new opportunities for your team. Our platform is ready to help you achieve success and bring your most ambitious projects to life.</p>
          </div>
          <div className="col-md-9">
            <div className="s-10"></div>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-4">
                <p className="fz-14 c-white w-400 my-3"><a href="/candidats">Find Talent</a></p>
                <p className="fz-14 c-white w-400 my-3"><a href="/about">About</a></p>
                <p className="fz-14 c-white w-400 my-3"><a href="/calculator">Team Builder</a></p>
                <p className="fz-14 c-white w-400 my-3"><a href="/blog">Blog</a></p>
                <p className="fz-14 c-white w-400 my-3"><a href="/resources">Resources</a></p>
              </div>
              <div className="col-md-4">
                <p className="fz-14 c-white w-400 my-3"><a href='/support'>Support</a></p>
                <p className="fz-14 c-white w-400 my-3"><a href='/contact'>Contact</a></p>
                <p className="fz-14 c-white w-400 my-3"><a href='/privacy'>Privacy Policy</a></p>
                <p className="fz-14 c-white w-400 my-3"><a href='/terms'>Terms of Use</a></p>
              </div>
              <div className="col-md-3">
                <div className="s-10"></div>
                <div className="flex jcfe aic">
                  <a href={gUrl}><img src={gIcon} alt="github" /></a>
                  <div className="w-10"></div>
                  <a href={lUrl}><img src={lIcon} alt="github" /></a>
                  <div className="w-10"></div>
                  <a href={fUrl}><img src={fIcon} alt="github" /></a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-right">
                <div className="s-30"></div>
                <p className="fz-14 c-white w-400"><a href="/">HeyBoss</a> @ 2023 - All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export {Footer}
