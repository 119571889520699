import React from "react";
import "./App.css";
import {Nav} from "./components/home/Nav";
import "./styles/custom.sass";
import { Routes, Route, Navigate } from "react-router-dom";

import { HomePage } from "./components/pages/HomePage";
import { LoginPage } from "./components/pages/LoginPage";
import { ProfilePage } from "./components/pages/ProfilePage";

import {PrivacyPage} from './components/pages/PrivacyPage'
import { CandidatsPage } from './components/pages/CandidatsPage'
import {SingleTalent} from './components/pages/singles/SingleTalent'
import {SinglePost} from './components/pages/singles/SinglePost'
import {EditPost} from './components/pages/singles/EditPost'
import {NewPost} from './components/pages/singles/NewPost'
import {BlogPage} from './components/pages/BlogPage'
import {NotFound} from './components/pages/NotFound'

import { RequireAuth } from "./hoc/RequireAuth";
import { AuthProvider } from "./hoc/AuthProvider";
import {Registration} from "./components/register/Registration";

const App = () => {
  return (
    <AuthProvider>
      <Nav />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="register" element={<Registration />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="privacy" element={<PrivacyPage />} />
        <Route path="candidats" element={<CandidatsPage />} />
        <Route path="candidats/:id/:price" element={<SingleTalent />} />
        <Route path="posts" element={<BlogPage />} />
        <Route path="posts/:id" element={<SinglePost />} />
        <Route path="posts/:id/edit" element={<EditPost />} />
        <Route path="posts/new" element={<NewPost />} />
        <Route path="posts/new/edit" element={<Navigate to="*" replace />} />
        <Route
          path="profile"
          element={
            <RequireAuth>
              <ProfilePage />
            </RequireAuth>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthProvider>
  );
};

export { App };
