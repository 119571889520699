import { useNavigate } from "react-router-dom"
import { useAuth } from "../../hook/useAuth"
import { useState } from "react"
import axios from "axios"

import {FormTitle} from '../elements/FormTitle'

const LoginPage = () => {
  const navigate = useNavigate()

  const { signIn } = useAuth()

  const [emailInputVal, setEmailInputVal] = useState("")
  const [passwordInputVal, setPasswordInputVal] = useState("")
  const [isError, setIsError] = useState(false)

  const userLogin = {
    email: emailInputVal,
    password: passwordInputVal,
  }

  function decodeToken(token) {
    const base64Url = token.split(".")[1]
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    const rawToken = atob(base64)
    const decodedToken = JSON.parse(rawToken)
    return decodedToken
  }

  function compareDate(timestamp) {
    const date = new Date(timestamp * 1000)
    const currentDate = new Date()
    if (date < currentDate) {
      console.log("Срок действия вэб токена уже истек")
    } else {
      const timeDiff = date.getTime() - currentDate.getTime()
      const remainingHours = Math.floor(timeDiff / (1000 * 60 * 60))
      console.log(
        `До окончания срока действия токена осталось ${remainingHours} часов`
      )
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const resp = await axios.post(
        "https://api.heyboss.online/api/login/",
        userLogin
      )
      const { access } = resp.data

      axios.defaults.headers.common["Authorization"] = `Bearer ${access}`

      const decodedToken = decodeToken(access)
      compareDate(decodedToken.exp)

      const userData = await axios.get(
        "https://api.heyboss.online/api/user/me/"
      )
      console.log(userData.data)
      const FN = userData.data.first_name
      const LN = userData.data.last_name

      localStorage.setItem("access", access)

      signIn([FN, LN], () => navigate("/profile" || "/", { replace: true }))
    } catch (error) {
      error && setIsError(true)
    }
  }

  return (
    <section className="bg-dark w-100 flex jcc">
      <div className="w-100">
        <div className="s-100"></div>
        <div className="s-100"></div>
        <div className="s-100"></div>
        <div className="s-100"></div>
        <div className="s-100"></div>
        <div className="container text-center">
          <form
            onSubmit={handleSubmit}
            className="card-form w-60 ma"
          >
            <div>
              <FormTitle
                title='Sign in'
                text='Enter your credentials to access your account'
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                placeholder="email"
                value={emailInputVal}
                onChange={(e) => setEmailInputVal(e.target.value)}
                className="input email ma fz-16 c-dark"
              />
              <input
                name="password"
                type="password"
                placeholder="password"
                value={passwordInputVal}
                onChange={(e) => setPasswordInputVal(e.target.value)}
                className="input password ma fz-16 c-dark"
              />
            </div>
            <button type="submit" className="button bg-sub w-70 rad-16">SignIn</button>
            {isError && <p className="alert w-70 ma fz-14 c-pink mt-4">Wrong email or password 🙈</p>}
            <div className="s-20"></div>
            <div className="text-center w-70 ma">
              <p className="c-white fz-13 fw-600 mt-1 mb-3">By clicking Sign In, you agree to our <a href='/privacy'>Privacy Policy</a> and consent to the collection of your data.</p>
              <p className="c-white fz-13 fw-600 my-3"><a href='/forgot'>Forgot Password?</a></p>
              <p className="c-white fz-13 fw-600 my-3">Don’t have an account yet?
                <br /><a href='/register'>Register</a>
              </p>
            </div>
          </form>
          <div className="s-100"></div>
          <div className="s-100"></div>
        </div>
      </div>
    </section>
  )
}

export { LoginPage }
