import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'

import 'typeface-inter'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/slick/slick.css'
import './styles/slick/slick-theme.css'
import {App} from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
)
