import React from 'react'
import { Link } from 'react-router-dom'
import { SectionTitle } from '../elements/SectionTitle'
import demo from '../../assets/back/demo.svg'

const Calculator = () => {
  return (
    <section id='demo' className='auto'>
      <div className='container text-center'>
        <SectionTitle
          title="Build Your Dream Team"
          text="Customize Your Team and Calculate Subscription Cost"
        />
        <div className="s-50"></div>
        <img src={demo} alt='Calculator' className="w-80 ma" />
        <div className="s-60"></div>
        <Link to='/login' className='button blue mx-2'>Join now</Link>
        <Link to='/demo' className='button mx-2'>view DEmo</Link>
      </div>
    </section>
  )
}

export {Calculator}
